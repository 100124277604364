
.themed-icon-wrap {
  display: inline-block;
  width: 28px;
  height: 21px;
  background-color: var(--primary);
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-size: contain;
  -webkit-mask-size: contain;
  
  mask-position: center;
  -webkit-mask-position: center;
  cursor: pointer;
}

.themed-icon-wrap.themed-icon-secondary { 
  background-color: var(--secondary);
}




.icon-checkmark:after {
  content:"";
  display:block;
  left: 7px;
  top: 3px;
  width: 8px;
  height: 13px;
  border: solid black;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}