.modal.show .modal-dialog.modal-full {
    top: 3%;
    height: 95%;
    padding: 0 5px;
    margin: 0 auto;
    max-width: 740px;
}
.modal-full .modal-content{
  height: 100%;
}
.modal-full .modal-footer {
  padding: 0 11px 11px 11px;
}


