@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');

.preview-processing-wrap {
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  display: block;
  position: absolute;
  z-index: 600;
  padding: 10px 28px;
}
.preview-processing {
  color:black;
  background: rgba(255,255,255,0.9);
  display: inline-block;
  width: 100%;
  z-index: 400;
  border-radius: 13px;
  padding: 20px;
}
.preview-processing-text{
  font-size: 16px;
  margin-bottom: 8px;
}
.preview-processing-wrap .lds-spinner{
  transform: scale(50%);
  height: 32px;
}

@media (min-width: 768px)   {
  /* MD and larger */
  
  .preview-processing-text {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .preview-processing {
    width: 70%;
    margin: auto;
  }

  .preview-processing-wrap {
    top: 10%;
    padding: 20px 45px;
  }
  .preview-processing-wrap .lds-spinner{
    transform: scale(100%);
    width: 64px;
    height: 64px;
  }
}



.account-save-header {
  text-align: center;
  background-color: var(--primary);
  color: white;
  margin-bottom: 30px;
}

.account-save-text{
  color: #E8E8E8;
  font-weight: bolder;
  font-family: 'Raleway', sans-serif !important;
  margin-top: 30px;
}


button.v-bigPlay.v-controlButton {
  background-color: var(--primary);
  border-radius: 100%;
  padding: 2px;
  width: 68px;
  height: 68px;
}

