
  
  .or-line {
    color: #959595;
    width: 100%; 
    text-align: center; 
    border-bottom: 1px solid var(--secondary); 
    line-height: 0.1em;
    margin: 20px 0 20px; 
  }

  .or-line span {
    background:#fff; 
    padding:0 10px; 
  }