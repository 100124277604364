.video-thumb {
    position: relative;
}
.video-thumb:after {
    content: "";
    width: 15%;
    height: 100%;
    top: 0;
    left: 50%;
    margin-left: -9%;
    position: absolute;
    background: url(../images/vf-icons/play-white.svg);
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 1;
    transition: all 0.5s ease;
}

.video-thumb:hover:after {
    margin-left: -11%;
    width: 19%;
    opacity: 0.5;
}
