@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap');

.btn-block {
    display: block;
    width: 100%;
}

@media (min-width: 992px) {
  .btn-lg-inline-block, .btn-block.btn-lg-inline-block {
    display: inline-block;
    width: auto;
  }
  .btn-block+.btn-block.btn-lg-inline-block {
    margin-top:0;
  }
}

@media (min-width: 768px) {
  .btn-md-inline-block, .btn-block.btn-md-inline-block{
    display: inline-block;
    width: auto;
  }
  .btn-block+.btn-block.btn-lg-inline-block {
    margin-top:0;
  }
}


.btn {
  font-size: 1.5rem;
  border-radius: 10px;
  margin-bottom: 0;
}

.btn-primary {
  background-color: var(--primary-btn);
  border: 1px solid var(--primary-btn);
  color: white;

  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 18px;
  text-transform: uppercase;

  transition: all 0.3s;
}

.btn-primary:hover, 
.btn-primary:active, 
.btn-primary:focus,
.btn-primary:focus-visible {
  background-color: var(--primary-hover) !important;
  border-color: var(--primary) !important;
}

.btn-secondary {
  background-color: var(--secondary-btn);
  border: 1px solid var(--secondary-btn);
  color: white;

  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 18px;
  text-transform: uppercase;
}

.btn-secondary:hover, 
.btn-secondary:active, 
.btn-secondary:focus,
.btn-secondary:focus-visible {
  background-color: var(--secondary-hover) !important;
  border-color: var(--secondary-btn) !important;
}

.btn-outline-primary {
  background-color: white;
  border: 1px solid var(--primary-btn);
  color: var(--primary-btn);

  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 18px;
  text-transform: uppercase;
}

.btn-outline-primary:hover,
.btn-outline-primary:active,
.btn-outline-primary:focus,
.btn-outline-primary:focus-visible {
  background-color: var(--primary-btn) !important;
  border-color: var(--primary-btn) !important;
  color: white;
}

.btn-outline-secondary {
  background-color: white;
  border: 1px solid var(--secondary-btn);
  color: var(--secondary-btn);

  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 18px;
  text-transform: uppercase;
}

.btn-outline-secondary:hover,
.btn-outline-secondary:active,
.btn-outline-secondary:focus,
.btn-outline-secondary:focus-visible {
  background-color: var(--secondary-btn) !important;
  border-color: var(--secondary-btn) !important;
  color: white;
}

@media (max-width: 991px) {
  .mobile-btn-right {
    border-radius: 10px 0 0 10px !important;
    margin-right: -15px !important;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.2);
  }
}

.close-button {
  background-color: var(--primary-btn) !important;
}

.close-button:hover {
  cursor: pointer;
}

.to-right-edge-btn {
  padding: 5px 30px 5px 30px;
  border-radius: 10px;
  margin-right: 5px;
}

@media (max-width: 577px) {
  .to-right-edge-btn {
    right: -5px;
    padding: 5px 20px 5px 20px;
    border-radius: 10px 0 0 10px;
    border-right: none;
    margin-right: 0px;
    width: 80%;
  }
}

@media (max-width: 320px) {
  .to-right-edge-btn {
    width: 90%;
  }
}


@media (max-width: 991px) {
  .mobile-btn-left {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    margin-left: -15px;
    margin-bottom: 16px !important;
    border-left-color: rgba(0, 0, 0, 0) !important;
    min-width: 240px;
    max-width: 80%;
    border-width: 2px !important;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.2);
  }
}


.edit-footer .btn-link {
  display: inline-block;
}

.btn-link {
  color: var(--blue-link);
  text-decoration: underline;
  font-size: 20px;
  font-weight:500;
  padding: 0;
  vertical-align: baseline;
}
.btn-link:hover {
  color: var(--blue-link);
  text-decoration: underline;
}
.btn-link.focus,
.btn-link:focus,
.btn-link:focus-visible {
  text-decoration: underline;
}


@media (min-width: 992px) {
  .next-btn {
    padding-left: 75px;
    padding-right: 75px;
  }
}

.btn-small,  .btn-link.btn-small {
    font-size: 16px;
}


.pulse {            
    animation: pulse 0.7s;
}
@keyframes pulse {
    0% {
        box-shadow: 0px 0px 0px 0px var(--primary-btn);
    }
    100% {
       box-shadow: 0px 0px 15px 15px var(--primary-hover);
    }
}

.btn-link.btn-share {
  padding: 0.1rem;
  font-weight: 100 !important;
  color: black !important;
  text-decoration: none;
  font-size: 16px !important;
}

.btn-share-icon {
  margin-right: 1rem;
}


.btn-share img {
  margin-right: 0.5rem;
}

