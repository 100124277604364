

/* modifies the styling for custom component AddButtonGroup */
.btn-upload > input#file {
  display: none;
}

.dropdown-item:active {
  text-decoration: none;
  background-color: var(--primary);
}
