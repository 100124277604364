
.notify-float {
  position: fixed;
  width: 100%;
  left: 0;
  top: 20px;
  z-index: 1100; /* dialog is at 1050 */
}

.notify, .alert-danger {
  background: #2c8636;
  border-radius: 8px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16);
  position: relative;
  padding: 10px 62px 10px 16px;
}

.alert-danger {
  background: #ba0000;
  color: white;
}


.notify h1 {
  font-size: 18px;
  color: white;
  letter-spacing: 0px;
  line-height: 30px;
  margin: 0 0 4px 0;
}
.notify h3 {
  font-size: 13px;
  color: white;
  letter-spacing: 0.4px;
  line-height: 20px;
  margin: 0 0 4px 0;
}
.notify p {
  font-size: 15px;
  color: white;
  letter-spacing: 0px;
  line-height: 24px;
  margin: 0;
}
.notify i.icon-mask.notify-close {
    background-color: white;
    top: 6px;
    right: 8px;
    position: absolute;
}