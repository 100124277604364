
.loading-bar {
  height: 10px;
  background-color: var(--primary);
  padding: 0;
  margin: 0;
  box-sizing: content-box;
}
.loading-bar-wrap {
  display: inline-block;
  background: white;
  border: 1px solid var(--primary);
  border-radius: 4px;
  overflow: hidden;
  width: 100%;
  margin: 1px 0px;
}


