
/* record fizz design start */

.video-view {
  position: relative;
  /* width: 300px; */
  /* height: 100px; */
}

.video-view .video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #ccc;
}

.video-view .video-content {
  position: absolute;
  /* bottom: 0px; */
  right: 1%;
  top: 2%;
  width: 10%;
  min-width:66px;
  z-index: 501;
  text-align: right;
}

.video_view_btn {
  background-color: transparent;
  border: none;
  z-index: 501;
  text-align: right;
  display:inline-block;
  padding:0;
  width: 100%;
}
.video_view_btn img {
  width: 100%;
}

.video-view .box-480{
  background-color: black;
  position: relative;
}


@media (min-width: 768px)   {
  /* MD and larger */

  .video_view_btnimg {
    width: 100px;
  }

}




.record-clock {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 0;
    width: 120px;
    padding: 3px 0;
    background-color: rgba(255,255,255,0.5);
    text-align: center;
}

.snapPill {
  background-color: #FFFC00;
  padding: 10px;
  border-radius: 10px;
  margin-top: 0.5rem;  
  margin-bottom: 0.5rem;
}



.snap-filter-bar {
  bottom: 1%;
  width: 100%;
  left: 0;
  z-index: 500;
  position: absolute;
  text-align: left;
  min-height: 10%;
}

.snap-filter-btn {
  border: none;
  width: 10%;
  background: none;
  padding: 0;
}

button.snap-filter-btn.active  img {
  border-radius: 100%;
}

button.snap-filter-btn {
  border-radius: 100%;
    margin: 0.8%;
  display: inline-block;
  opacity: .9;
}

button.snap-filter-btn.active {
  background: white;
  position: relative;
  opacity: 1;
}
button.snap-filter-btn.active::before {
    width: 106%;
    height: 106%;
    content: "";
    display: block;
    position: absolute;
    top: -3%;
    left: -3%;
    background-color: var(--primary);
    border-radius: 100%;
    z-index: -1;
}

#tos-popup {
    z-index: 1000; /* 1000 is above the filters */
    position: absolute;
    top: 0;
    left: 0;
}


