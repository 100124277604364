
.fsf-editor {
    border: 1px solid #ced4da;
    border-top: none;
    border-radius: 0.25rem;
  
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
  
    z-index: 20;
    position: relative;
    background: white;
  }
  
  
  