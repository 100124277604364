

/* 
:root {
  --page-background: #EBEBEB;

  --primary: #3290A5;
  --secondary: #F7A43D;

  --primary-hover: #3290A5B4;
  --secondary-hover: #F7A43DB4
} 
*/


i.track-category {
  font-size: 1rem;
  padding: 8px 16px;
  border: 1px solid #D9D9D9;
  border-radius: 18px;
  margin: 2px;
  cursor: pointer;
}

i.track-category.active {
  background: var( --primary);
  color: white;
  border: none;
}

.musicOptions {
  padding: 1rem;
  background-color: #202020;
  color: white;
}

.music-picker-list {
  overflow-y: scroll;
  flex-grow: 1;
  flex-basis: 180px;
}

.music-picker-list .icon-checkmark {
  position: absolute;
  left: 20px;
  max-height: 400px;
}
.music-picker-list .icon-checkmark, .sound-picker-list .icon-checkmark {
  position: absolute;
  left: 20px;
}

.modal .music-picker {
  height: 100%;
  display: flex;
  flex-direction: column;
}

@media (min-width: 992px) {
  .music-picker {    
    height: 65vh;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
}




/*** Equalizer styling ***/
/* Wrapper for Equalizer within MusicModal */
.equalizer-wrapper {
  width: 60px;
  height: 42px;
}

.equalizer {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 3px;
  width: 100%;
  height: 100%;
}
.equalizer .bar {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: var(--primary);;
}
.equalizer .bar > span {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  transition: transform 250ms cubic-bezier(.11,.32,.38,.91);
}

@media (max-width: 565px) {
  .equalizer-wrapper {
    width: 40px;
    height: 28px;
  }
  .equalizer {
    grid-gap: 2px;
  }
  .picked-track-name {
    font-size: 1rem;
    width: 60%;
    margin-right: -20px; /* mobile compensation for equalizer offset */
}
  .music-modal .modal-body > div:first-child {
    padding: 8px;
  }
  i.track-category {
    font-size: 0.8rem;
    padding: 4px 8px;
    margin: 1px;
  }
}


.music-picker .themed-icon-wrap  {
  width: 21px;
  height: 14px;
  background-color: var( --primary );
}

.music-picker .themed-icon-wrap  {
  width: 18px;
  height: 21px;
}

/* Make music modal icon smaller on xs devices */
@media (max-width: 565px) {
  .music-picker .edit-footer .themed-icon-wrap {
    width: 13px;
    height: 15px;
  }
}

.music-picker .edit-footer .themed-icon-wrap {
  background-color: var( --primary);
}

@media (min-width: 992px) {
  .mobile-list, #mobile-move-hint, .mobile-upload-item-wrapper, .mobile-handle {
    display: none !important;
  }
}

.music-picker .themed-icon-wrap:not(.primary) {
  background-color: var(--primary);
  cursor: pointer;
  min-width: 16px;
}

.music-picker .themed-icon-wrap.primary {
  width: 28px;
  margin-left: auto;
  min-width: 28px;
}

.track-name {
  margin-left: 16px;
  flex-grow: 1;
}

.picked-track-name {
  font-size: 1.2rem;
  color: var( --primary );
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  flex-grow: 1;
  padding: 0 8px;
  margin: 0;
}

.list-group-item.music-track:first-child {
  border-top: 2px solid #F1F1F1;
}

.list-group-item.music-track {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-bottom: 3px solid #F1F1F1;
  border-radius: 0;
  margin: 0;
  padding: 16px;
  cursor: pointer;
}
  


