
.home-cup {
  padding-top:7vw;
  padding-bottom: 7.246vw;
  padding-left: 20px;
  background-image: linear-gradient(to bottom, #fdf3ee 0%, #f9f5f2 100%);
}


.home-cup h3,
.home-cup h2 {
  color: #ef6a53;
}

.home-cup h2 {
  margin-bottom: 3.623vw;
}

.home-cup p {
  font-size: 4.106vw;
  margin-bottom:5px;
}



.home-cup .text {
  padding-left: 5%;
  width: 90%;
}

@media (min-width: 768px) {
  .home-cup p {
    font-size: 18px;
  }

}

.home-cup .btn {
  display: none;
}


.home-cup .image-square {
  position: relative;
  margin-top: 10px;
  height: 0;
  width:60%;
  padding-bottom:60%;
  display: inline-block;
}


.home-cup .image {
  position: absolute !important;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 3px solid #86b95b;
  display: block;
  margin: 0 auto;
  position: relative;
  z-index: 5;
}

.home-cup .image:after {
  content: "";
  position: absolute;
    height: 179.115%;
    width: 129.115%;
    left: 11.667%;
    bottom: -47.719%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(//cdn.shopify.com/s/files/1/0594/1746/0901/t/3/assets/image-decor.png?v=67216802296736579521630941327);
  z-index: -1;
}

.home-cup .image img {
  height: 100%;
  display: block;
  border-radius: 100%;
}


.home-cup .btn {
  justify-content: center;
  align-items: center;
  padding: 8px 40px;
  line-height: 1;
  font-size: 16px;
  font-weight: 700;
  transition: .2s;
  outline: none;
  cursor: pointer;
  border-radius: 50px;
  letter-spacing: .1em;
  text-align: center;
  text-transform: none;
}

@media (min-width: 768px) {
  .home-cup {
    padding-top: 20px;
    padding-bottom: 20px;
    background-image: linear-gradient(to bottom, #fdf3ee 0%, #f8f6f3 100%);
    text-align: center;
  }
    

  .home-cup .image-square {
    width:40%;
    padding-bottom:40%;
  }

  .home-cup h2 {
    margin-bottom: 10px;
  }
  
  .home-cup h3 {
    margin-top: 10px;
    margin-bottom: 6px;
  }
  
  .home-cup .text {
    padding-left: 0;
    width: 47%;
    margin-right: 30px;
  }
  
  .home-cup .wrapper {
    display: flex;
    align-items: flex-start;
    align-items: center;
  }
  
  .home-cup .btn {
    display: inline-block;
  }
  
}