
.invite-info {
  background-color: #f3f3f3;
  margin: 0px auto;
  padding: 20px;
  font-size: 12px;
}

textarea.invite-info {
  display: block;
  font-size: 16px;
  line-height: 25px;
  z-index: 2;
  margin: 0;
  border: none;
  border-radius: 0;
  color: #48464f;
  background-color: #f3f3f3;
  min-height: 200px;
  width: 100%;
  padding: 20px;
}