.video-player {
    position: relative;
}
.video-player video {
    width: 100%;
    
}


.video-player .time {
    color: gray;
    min-width: 120px;
}


video { 
  background-color: black;
}



.video-player input[type="range"] {
    width: 100%;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}
input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 25rem;
}

/* Removes default focus */
input[type="range"]:focus {
  outline: none;
}

/******** Chrome, Safari, Opera and Edge Chromium styles ********/
/* slider track */
input[type="range"]::-webkit-slider-runnable-track {
  background-color: #ededed;
  border-radius: 0.5rem;
  height: 0.5rem;
}

/* slider thumb */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -4px; /* Centers thumb on the track */
  background-color: #e3e3e3;
  border-radius: 0.5rem;
  height: 1rem;
  width: 0.5rem;
}

input[type="range"]:focus::-webkit-slider-thumb {
  outline: 3px solid #e3e3e3;
  outline-offset: 0.125rem;
}

/*********** Firefox styles ***********/
/* slider track */
input[type="range"]::-moz-range-track {
  background-color: #ededed;
  border-radius: 0.5rem;
  height: 0.5rem;
}

/* slider thumb */
input[type="range"]::-moz-range-thumb {
  background-color: #e3e3e3;
  border: none; /*Removes extra border that FF applies*/
  border-radius: 0.5rem;
  height: 1rem;
  width: 0.5rem;
}

input[type="range"]:focus::-moz-range-thumb{
  outline: 3px solid #e3e3e3;
  outline-offset: 0.125rem;
}



/*Fill the left as a different color*/
/** FF*/
input[type="range"]::range-progress, 
input[type="range"]::-moz-range-progress, 
input[type="range"]::-ms-fill-lower {
  background-color: white; 
}