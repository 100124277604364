
.edit-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  padding: 0;
  z-index: 10;
}

.edit-footer .container {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: white;
  border-top: 15px solid var(--primary);
  border-right: none;
  border-left: none;
  text-align: right;

  -webkit-box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.75);
  -moz-box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.75);
  box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.75);
}

@media (min-width: 992px) {
  .preview-footer .container{
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media (max-width: 991px) {
  .edit-footer .container{
    border: none;
  }
  .footer-spacer{
    visibility: hidden;
    height: 160px;
    padding: 0;
  }
}
