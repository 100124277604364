@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');

/* 
:root {
  --page-background: #EBEBEB;

  --primary: #3290A5;
  --secondary: #F7A43D;

  --primary-hover: #3290A5B4;
  --secondary-hover: #F7A43DB4
} 
*/

html {
  font-family: 'Raleway', sans-serif !important;
}

body {
  background-color: var(--page-background);
  color: var(--body-text);
}

p {
  font-family: 'Raleway', sans-serif !important;
  font-size: 1rem;
}

h1 {
  font-family: 'Raleway', sans-serif !important;
  font-size: 40px;
}

@media (max-width: 565px) {
  /* h1 looks too big for mobile screen. Judgment call. */
  h1 {
    font-size: 3.2rem;
  }
}

h2 {
  font-family: 'Raleway', sans-serif !important;
}

h3 {
  font-family: 'Raleway', sans-serif !important;
  font-weight: 700;
}

main {
  padding-bottom: 20px;
}

ul {
  padding-left: 1rem;
}

.no-gap {
    margin-left: -15px;
    margin-right: -15px;
}

.screen-wrap {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.bg-margin {
  margin-bottom: 60px !important;
}


.header-branding {
  position: relative;
  background-color: white;
  box-shadow: 0px 3.55556px 3.55556px 3.55556px rgb(0 0 0 / 25%);
} 


main.container {
  flex-grow: 1;
  background: white;
  overflow: hidden;
}
footer.container {
  background: white;
}

@media (min-width: 576px) {

  /* Dont show bars on mobile */
  main.container {
    border-top: 20px var(--primary) solid;
  }
  footer.container {
    border-top: 20px var(--primary) solid;
  }
}

@media (max-width: 565px) {
  body, main.container {
    background: none;
    background-image: url(../images/bg-pattern.svg);
    background-size: contain;
    background-color: rgba(255, 255, 255, 0.7);
  }
}
 


.headline, .headline-text, .headline-simple {
  text-transform: uppercase;
  color: var(--headline-text);
  font-weight: 700;
}
.headline {
  display: flex;
  text-align: right;
  margin-top: 1.5rem!important;
  margin-bottom: 1.5rem!important;
  align-items: center;
}
.headline.headline-big {
  font-size: 60px;
}
.headline.headline-stretch {
  font-size: 7.2vw
}


@media (min-width: 576px) {
  .headline.headline-stretch {
    font-size: 40px;
  }
}

.headline::before, .headline-text::before {
  position: relative;
  content: '';
  display: block;
  height: 0px;
  flex-grow: 1;
  right: 12px;
  border-top: 0.25em var(--headline-bar) solid;
}

.headline.headline-no-bleed::before, .headline-text.headline-no-bleed::before {
  margin-left: 0;
}

@media (max-width: 575px) {
  .headline{
    font-size: 32px;
    line-height: 1.1;
    margin-top: 20px;
  }

  .headline::before, .headline-text::before {
    align-self: flex-start !important;
    margin-top: 15px;
  }

  h2.headline::before, h2.headline-text::before {
    align-self: center !important;
    margin-top: 0;
  }
}

.headline-large {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: var(--primary);
}


.info-strip {
  position: absolute;
  bottom: 5%;
  right: 0;
  margin-right: -8px;
  padding: 5px 30px 5px 20px;
  background-color: white;
  border-radius: 10px 0 0 10px;
  color: var(--secondary);
  box-shadow: -3px 3px 4px -2px rgba(0,0,0,0.7);
}

/* for CreateFizz textarea input - maybe no more needed */
#fizzMessageInput {
  min-height: 20vh;
}


.start-button {
  max-width: 340px;
}

@media (max-width: 991px) {
  .start-button-wrapper {
    margin-right: -30px;
  }
  .start-button {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

.step-thumbnail-wrapper {
  position: relative;
  border: 8px solid var(--primary);
}

@media (max-width: 575px) {
  .step-thumbnail-wrapper {
    border-right: none;
    border-left: none;
    margin-left: -15px;
    margin-right: -15px;
  }
}

.step-thumbnail-wrapper img {
  max-width: 100%;
  max-height: 100%;
}



.add-banner-wrapper {
  width: 100%;
  position: relative;
}

.site-link-button {
  position: absolute;
  bottom: 60px;
}
@media (max-width: 991px) {
  .site-link-button {
    position: absolute;
    bottom: 20px;
  }
}


/* one-off requirement for setup screen top CTA */
.me-6 {
  margin-right: 96px;
}

@media (min-width: 1020px) {
  .me-lg-6 {
    margin-right: 72px
  }
}


h2 > span {
  width: 200% !important;
}

@media (min-width: 576px) {
  h2 > span {
    width: 75% !important;
  }
}



.min-w-100 {
  min-width: 100%;
}

/* Edit Fizz */
.list-group-item {
  background-color: #e0e0e0;
  margin-top: 5px;
  padding-left: 40px;
}

.list-group-item i.fa {
    position: absolute;
    left: 18px;
    top: 14px;
}

.icon-down {
  margin-top: 0.3rem;
}

.musicOptions {
  padding: 1rem;
  background-color: #202020;
  color: white;
}
.music-picker-list .icon-checkmark {
  position: absolute;
  left: 20px;
}
.music-picker-list .icon-checkmark, .sound-picker-list .icon-checkmark {
  position: absolute;
  left: 20px;
}

.musicOptions > input[type="radio"] {
  -webkit-appearance: checkbox;
  -moz-appearance: checkbox;
  -ms-appearance: checkbox; /* not currently supported */
  -o-appearance: checkbox; /* not currently supported */
}

.plyr audio,
.plyr video {
  border-radius: inherit;
  /*max-height: 500px !important;   Some are larger. If needed add a new class to a wrapper. The preview page doesn't wokr with this*/
  vertical-align: middle;
  width: 100%;
}
.plyr:-webkit-full-screen video {
  max-height: none !important;
}

.icon-checkmark:after {
  content:"";
  display:block;
  left: 7px;
  top: 3px;
  width: 8px;
  height: 13px;
  border: solid black;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.hint {
  padding: 10px;
}


.grid-right-margin:not(:nth-child(4n)) {
  margin-right: 1.333%;
}


/* Modifying bootstrap class. Edge case. */
.d-lg-inline-block {
  margin-bottom: 16px;
}




.pull-right {
  padding: 8px;
  margin-left: auto;
}

small .themed-icon-wrapper {
  width: 21px;
  height: 14px;
  background-color: #606060;
  margin-right: 10px;
}

img.uploading-video, video.uploading-video {
  max-width: 100% !important;
}

/* set primary color for all themed SVG icons */
.themed-icon-wrapper {
  display: inline-block;
  width: 18px;
  height: 21px;
  background-color: var(--primary);
  background-repeat: no-repeat;
  background-position: center;
  mask-size: contain;
}

/* Make music modal icon smaller on xs devices */
@media (max-width: 565px) {
  .edit-footer .themed-icon-wrapper {
    width: 13px;
    height: 15px;
  }
}

.edit-footer .btn.blue-link {
  /* 
  For aligning the footer action items well in EditFizz.
  No need to resize music picker icon on very small devices anymore.
  */
  padding: 0 !important;
}

.icon-play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 18px;
  height: 19px;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  mask-size: cover;
  cursor: pointer;
}

.edit-footer .themed-icon-wrapper {
  background-color: var(--primary);
}


.text-right {
    text-align: right;
}
.text-center {
    text-align: center;
}




.box-480 {
  position: relative;
  width: 100%;
}
.box-480:before {
  width: 100%;
  height: 0;
  padding-top: 56.20608899%;
  content: " ";
  display: block;
}
.box-480-item {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.qr-share-group {
  margin-top: 100px;
  padding: 0px 30px 30px 0px;
}

.org-piac .headline-simple,
.org-ccc .headline-simple {
	letter-spacing: -0.06em;
	color: #7FC5BC;
	font-weight: 600;
	line-height: 1.3;
  text-transform: none;
}

.org-piac .headline-simple b,
.org-ccc .headline-simple b {
	font-weight: 800;
	color: #F42292;
}

.org-piac .body-text,
.org-ccc .body-text {
	color: #7FC5BC;
  text-transform: none;
}
.contact-text{
  margin-bottom: 1.5rem;
  font-weight: 300;
  text-align: center;
  font-size: 16px;

}

.org-piac .contact-text,
.org-ccc .contact-text {
  color: #212529;
}

.qr-ferrets{
  padding: 0px 50px 50px;
}

.thank-you-panel{
  background-color: white;
  padding: 30px;
}


@media (max-width: 992px) {

  .qr-ferrets{
    padding: 0px;
  }

}

@media (max-width: 768px) {

  .qr-share-group{
    margin-top: 0px;
  }

}